import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EventDto} from "../../models/event/event-dto";
import {EventEdto} from "../../models/event/event-edto";
import {LocationConfigLinker} from "../../models/location/location-config-linker";
import {LocationConfigLinkerNl} from "../../models/location/location-config-linker-nl";
import {Tuple} from "../../models/_generic/tuple";
import {EventNl} from "../../models/event/event-nl";
import {EmployeeEvent} from "../../models/event/employee-event";
import {EmployeeEventResponseNl} from "../../models/event/employee-event-response-nl";
import {EmployeeEventResponseDto} from "../../models/event/employee-event-response-dto";
import {LocationDto} from "../../models/location-dto";
import {ServerEndpoints} from "../../server.endpoints";
import {EventCreationModel} from "../../_modules/location/_models/event-creation-model";

@Injectable({
  providedIn: 'root'
})
export class EventHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  list(first: number = 0, last: number = 0, start: Date | null = null, end: Date | null = null, finished: boolean = false,
       filter: string = ""): Observable<EventDto[]> {
    return this.httpClient.get<EventDto[]>(this.baseUrl + ServerEndpoints.Inventory_Event_List + "?first=" +
      first +
      "&last=" + last +
      "&start=" + (start == null ? "" : start?.toISOString()) +
      "&end=" + (end == null ? "" : end?.toISOString()) +
      "&finished=" + finished +
      "&filter=" + filter
    );
  }

  test() {
    return this.httpClient.get(this.baseUrl + ServerEndpoints.Management_Hangfire_Authorize);
  }

  getExtendedEvent(id: string, location: string): Observable<EventEdto> {
    return this.httpClient.get<EventEdto>(this.baseUrl + ServerEndpoints.Inventory_Event_GetExtendedEvent + "?id=" + id + "&location=" + location);
  }

  getCurrentOrNext(): Observable<Tuple<EventDto, boolean> | undefined> {
    return this.httpClient.get<Tuple<EventDto, boolean> | undefined>(this.baseUrl + ServerEndpoints.Inventory_Event_GetCurrentOrNext);
  }

  getEvent(id: string): Observable<EventDto> {
    return this.httpClient.get<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_Get + "?id=" + id);
  }

  changeName(id: string, name: string): Observable<EventDto> {
    return this.httpClient.put<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_ChangeName, {
      Id: id,
      Name: name
    })
  }


  add(name: string, start: Date, end: Date): Observable<EventDto> {
    return this.httpClient.post<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_Add, {
      "Name": name,
      "Start": start.toISOString(),
      "End": end.toISOString()
    })
  }

  addMany(events: EventCreationModel[]): Observable<EventDto[]> {
    return this.httpClient.put<EventDto[]>(`${this.baseUrl}${ServerEndpoints.Inventory_Event_AddMany}`, events);
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Inventory_Event_Delete + "?id=" + id);
  }

  upsert(event: EventDto): Observable<EventDto> {
    return this.httpClient.put<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_Upsert, JSON.stringify(event));
  }

  download(id: string, supplier: string) {
    return this.httpClient.get(this.baseUrl + ServerEndpoints.Inventory_Event_Download + "?id=" + id + "&supplierId=" + supplier, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  downloads(ids: string[], supplier: string) {
    return this.httpClient.post(this.baseUrl + ServerEndpoints.Inventory_Event_Downloads + "?supplierId=" + supplier, ids, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    })
  }

  setLocationConfigs(id: string, locationConfigs: LocationConfigLinker[]): Observable<EventEdto> {
    let linkers: any[] = [];
    locationConfigs.forEach(l => {
      linkers.push({
        "Location": l.location.id,
        "Configuration": l.configuration?.id
      })
    })
    return this.httpClient.put<EventEdto>(this.baseUrl + ServerEndpoints.Inventory_Event_SetLocationConfigs + "?eventId=" + id, linkers);
  }

  changeTime(id: string, start: Date, end: Date): Observable<EventDto> {
    // Name is id in backend
    return this.httpClient.put<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_ChangeTime, {
      Id: id,
      Start: start.toISOString(),
      End: end.toISOString()
    })
  }

  setPublicProperty(id: string, name: string, value: string): Observable<EventDto> {
    return this.httpClient.put<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_SetPublicVariable + "?id=" + id, {
      name: name,
      value: value
    })
  }

  getPublicEvents(): Observable<EmployeeEvent[]> {
    return this.httpClient.get<EmployeeEvent[]>(this.baseUrl + ServerEndpoints.Inventory_Event_GetPublicEvents)
  }

  sendEmployeeEventResponse(shard: string, ev: string, response: EmployeeEventResponseNl): Observable<EmployeeEventResponseNl> {
    return this.httpClient.put<EmployeeEventResponseNl>(this.baseUrl + ServerEndpoints.Inventory_Event_SendEmployeeEventResponse + "?shard=" + shard + "&ev=" + ev,
      response
    )
  }

  changePublicResponseTime(id: string, time: Date): Observable<EventDto> {
    return this.httpClient.put<EventDto>(this.baseUrl + ServerEndpoints.Inventory_Event_ChangePublicResponseTime + "?id=" + id + "&time=" + time.toISOString(), {});
  }

  getEmployeeAvailability(date: Date): Observable<Tuple<EventNl[], EmployeeEventResponseDto[]>> {
    return this.httpClient.get<Tuple<EventNl[], EmployeeEventResponseDto[]>>(this.baseUrl + ServerEndpoints.Inventory_Event_GetEmployeeAvailability + "?date=" + date.toISOString());
  }

  getPrivatePublicEvents(start: Date, end: Date): Observable<EventNl[]> {
    return this.httpClient.get<EventNl[]>(this.baseUrl + ServerEndpoints.Inventory_Event_GetPrivatePublicEvents + "?start=" + start.toISOString() + "&end=" + end.toISOString());
  }

  getDisabledLocations(date: Date): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + ServerEndpoints.Inventory_Event_GetDisabledLocations + "?date=" + date.toISOString());
  }
}
